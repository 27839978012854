<template>
   <div class="w-max-content px-1 mb-1">
      <span class="badge bg-theme" @click="remove">{{ pesquisa.valor }} <i class="fal fa-times ms-1"></i></span>
   </div>
</template>

<script>

export default {
   name: 'Pesquisa',
   props: ['pesquisa', 'index'],
   methods: {
      remove : function () {
         this.$store.dispatch('deletePesquisa', this.index)
      }
   }
}

</script>

<style scoped>

.badge {
   font-weight: 400;
   padding: 10px 15px;
   font-size: 13px;
   cursor: pointer;
   white-space: break-spaces;
}

.badge i {
   font-size: 90%;
   transform: translateY(1px);
}

</style>