<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="breadcrumb__wrapper">
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0">
                     <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                     <li class="breadcrumb-item active" aria-current="page">Produtos</li>
                  </ol>
               </nav>
            </div>
         </div>
      </section>

      <!-- produtos area start -->
      <section class="product__area pt-20 pb-40 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-xxl-2 col-xl-3 col-lg-4 pe-lg-0 mb-2 mb-lg-0">
                  <div class="product__widget bg-white p-3 p-xl-4">
                     <div class="product__widget-item mb-20">
                        <div class="form-check form-switch">
                           <input class="form-check-input" type="checkbox" id="switchProdutosDesabilitados" :checked="pesquisaDesabilitado" @click="toggleDesabilitados">
                           <label class="form-check-label" for="switchProdutosDesabilitados">Incluir descontinuados</label>
                        </div>
                     </div>

                     <div class="product__widget-item mb-20">
                        <div class="row m-0">
                           <historico v-for="(historico, index) in pesquisa.historico" :key="index" :pesquisa="historico" :index="index" />
                        </div>
                     </div>

                     <div class="product__widget-item">
                        <div class="accordion">
                           <filtro v-for="(filtro, index) in pesquisa.filtros" :key="index" :filtro="filtro" :index="index" />
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-xxl-10 col-xl-9 col-lg-8 ps-lg-1">
                  <div class="cards-170 cards-sm-200 cards-xl-225" v-if="pesquisa.resultado != null && pesquisa.resultado.length > 0">
                     <produto v-for="(produto, index) in pesquisa.resultado" :key="index" :produto="produto" :index="index" :view="'Pesquisa'" />
                  </div>
                  <div v-if="pesquisa.resultado == null || pesquisa.resultado.length == 0" class="mb-200">
                     <div class="error__content text-center">
                        <div class="error__number">
                           <h1 class="mt-50 mb-50"><i class="far fa-search"></i></h1>
                        </div>
                        <span class="d-none d-sm-block">Sua pesquisa não encontrou nenhum produto correspondente.</span>
                        <h4 class="mt-3 mb-4">Nenhum produto encontrado.</h4>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

		<!-- relacionados area start -->
      <relacionados :array="pesquisa.relacionados" :titulo="'Relacionados'" :classes="'relacionados__area pt-15 pb-30 grey-bg'" />
   </main>
</template>

<script>

import { mapState } from 'vuex'
import filtro from '@/components/home/Filtro.vue'
import produto from '@/components/produtos/Produto.vue'
import historico from '@/components/home/Pesquisa.vue'
import relacionados from '@/components/CarouselProdutos.vue'

export default {
   name: 'Categorias',
   computed: {
		... mapState({
			pesquisa: state => state.pesquisa,
         pesquisaDesabilitado: state => state.pesquisaDesabilitado
		})
	},
   watch: {
      pesquisaDesabilitado : function (value) {
         this.$store.dispatch('pesquisar', {'categoria': this.pesquisa.categoria, 'subcategoria': this.pesquisa.subcategoria, 'tipoPesquisa': 'Desabilitados', 'valor': value})
      }
   },
   methods: {
      toggleDesabilitados : function () {
         this.$store.dispatch('togglePesquisarDesabilitados')
      }
   },
   components: {
      produto, filtro, historico, relacionados
   },
   mounted() {
      if (this.pesquisa.historico.length == 0) {
         this.$store.dispatch('setPesquisa', {'categoria': '', 'subcategoria': '', 'tipoPesquisa': 'Geral', 'valor': ''})
      } else {
         this.$store.dispatch('pesquisar', {'categoria': this.pesquisa.categoria, 'subcategoria': this.pesquisa.subcategoria, 'tipoPesquisa': 'Desabilitados', 'valor': this.pesquisaDesabilitado})
      }
   }
}

</script>

<style scoped>

#switchProdutosDesabilitados {
   margin-top: 6px;
}

</style>