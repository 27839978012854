<template>
   <div class="accordion-item mb-15">
      <h2 class="accordion-header" :id="'heading'+ index">
         <button class="accordion-button font-15 pe-4" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+ index" :aria-controls="'collapse'+ index"
            :class="index != 0 ? 'collapsed' : ''" :aria-expanded="index == 0">
            {{ filtro.nome }}
         </button>
      </h2>
      <div :id="'collapse'+ index" class="accordion-collapse collapse" :class="index != 0 ? '' : 'show'" :aria-labelledby="'heading'+ index">
         <div class="accordion-body py-3">
            <campo v-for="(campo, index) in filtro.campos" :key="index" :campo="campo" :filtro="filtro.nome" />
         </div>
      </div>
   </div>
</template>

<script>

import campo from '@/components/home/FiltroCampo.vue'

export default {
   name: 'Filtro',
   props: ['filtro', 'index'],
   components: {
      campo
   }
}

</script>